<template>
  <div class="home">
    <Section light>
      <h1>Apply</h1>
      <v-col class="px-4" cols="12">
        <p>
          The 2024-2025 application season will open this fall.
          In the mean time, fill out <a href="https://forms.gle/92JexrMFjLwPvm2M6">
          this short interest form</a> and we will contact you when
          2024-2025 applications open.
        </p>
        <div class="iframe-container">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSeYzoR4DjgFmwuF3wvFHepB5bczhLl_w21dY2pWjz9yx1hODA/viewform?embedded=true"
            width="640"
            height="1325"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </div>

        <br />
      </v-col>
    </Section>
  </div>
</template>

<script>
import Section from '@/components/Section.vue';

export default {
  name: 'Apply',
  components: {
    Section,
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}
h2 {
  text-align: center;
}
.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px;
}
.iframe-container iframe {
  width: 100%;
  max-width: 640px;
  height: 80vh;
  border: none;
}
a {
  color: white;
}
a:visited {
  color: white;
}
a:link {
  color: white;
}
</style>
